import Template from '../Template'
import * as style from './style.module.scss'
import axios from 'axios'
import Link from 'gatsby-link'
import React, { useState, useRef } from 'react'
import { Helmet } from 'react-helmet'
import {
  unstable_useFormState as useFormState,
  unstable_Form as Form,
  unstable_FormCheckbox as FormCheckbox,
  unstable_FormGroup as FormGroup,
  unstable_FormLabel as FormLabel,
  unstable_FormInput as FormInput,
  unstable_FormMessage as FormMessage,
  unstable_FormSubmitButton as FormSubmitButton,
} from 'reakit/Form'

function PageAddBusiness(props) {
  const [submitted, setSubmitted] = useState(false)
  const imageInputRef = useRef()
  const iconInputRef = useRef()
  const form = useFormState({
    values: {
      name: '',
      email: '',
      businessDescription: '',
      categories: [],
      city: '',
      facebook: '',
      instagram: '',
      phone: '',
      state: '',
      streetAddress: '',
      website: '',
    },
    onSubmit: async (values) => {
      let geoLocation
      if (values?.streetAddress) {
        geoLocation = await axios.get(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${values.streetAddress}.json?country=us&limit=1&proximity=ip&autocomplete=false&access_token=pk.eyJ1Ijoid29rZWphY3F1ZWxpbmUiLCJhIjoiY2w0NW8wMHN5MDBmdzNlbzBzZDRkOHJ0dCJ9.zbX5Gz2z-8JouldxtoG84g`
        )
      }
      let businessDescriptionEdit = ''
      if (values?.businessDescription) {
        //Escape Strings, then remove white space if its there.
        businessDescriptionEdit = values.businessDescription
          .replace(/\\/g, '\\\\')
          .replace(/\"/g, '\\"')
          .replace(/[\r\n]/gm, '')
      }
      const id = values.name.split(' ').join('-').toLowerCase()
      const formattedText = `export const frontmatter = {
  meta: { 
    title: "${values.name}",
    description:"${values?.businessDescription ? businessDescriptionEdit : ''}",
  },
  business_hours: {},
  categories: ${values?.categories ? JSON.stringify(values.categories) : ['']},
  city: '${values.city ?? null}',
  date: '${new Date().toISOString()}',
  email: '${values.email}',
  id: '${id}',
  icon: './icon.jpg',
  image: './image.jpg',
  tags: [''],
  tags_private: [''],
  _geoloc: {
    lat: ${geoLocation?.data.features[0].geometry.coordinates[1] ?? 0},
    lng:  ${geoLocation?.data.features[0].geometry.coordinates[0] ?? 0},
  },
  phone: '${values.phone ?? null}',
  post_title: "${values.name}",
  post_tag: '',
  state: '${values.state ?? null}',
  social_media: {
    facebook_url: '${values?.facebook ?? null}',
    instagram_url: '${values?.instagram ?? null}',
  },
  street_address: '${values?.streetAddress ?? null}',
  website: '${values?.website ?? null}',
}

${values.businessDescription}
    `
      const formData = new FormData()
      formData.append('formattedText', formattedText)
      formData.append('id', id)
      formData.append('name', values?.name)
      formData.append('email', values?.email)
      formData.append('phone', values?.phone)
      formData.append('website', values?.website)
      formData.append('categories', values?.categories)
      formData.append('streetAddress', values?.streetAddress)
      formData.append('city', values?.city)
      formData.append('state', values?.state)
      formData.append('instagram', values?.instagram)
      formData.append('facebook', values?.facebook)
      formData.append('businessDescription', values?.businessDescription)

      if (imageInputRef.current.files[0]) {
        formData.append('file', imageInputRef.current.files[0], 'image.jpg')
      }

      if (iconInputRef.current.files[0]) {
        formData.append('file', iconInputRef.current.files[0], 'icon.jpg')
      }

      try {
        await axios({
          method: 'post',
          url: '/api/business',
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
        })
      } catch (error) {
        throw new Error(error) // Default error
      }

      setSubmitted(true)
    },
    onValidate: (values) => {
      const errors = {}

      if (imageInputRef.current.files[0]?.size / 1024 > 5000) {
        errors.image = 'Please upload a smaller image. Max size 5MB'
      }

      if (iconInputRef.current.files[0]?.size / 1024 > 2000) {
        errors.logo = 'Please upload a smaller image. Max size 2MB'
      }

      if (Object.keys(errors).length) {
        throw errors
      }
    },
  })

  return (
    <Template>
      <Helmet>
        <title>
          Apply to be a dog sitter - {props.data.site.siteMetadata.title}
        </title>
      </Helmet>
      {submitted && (
        <div className={style.fadeIn}>
          <div className={style.hero}>
            <h1>Thank you!</h1>
            <p>Your application has been received.</p>
          </div>
          <div className={style.resultBox}>
            <h3>What happens next?</h3>
            <p>
              After your application is reviewed, you will receive an email with
              a link to start the background check process.
            </p>
            <h4>How long does the review process take?</h4>
            <p>
              Around 7-10 days. During periods of very high submissions, this
              can be as long as 2 weeks.
            </p>
            <h4>How long do background checks take?</h4>
            <p>
              Background checks are provided by a third-party expert,{' '}
              <a
                href='https://checkr.com/our-technology/background-check-api'
                target='_blank'
              >
                Checkr
              </a>
              , and are usually completed in under 10 minutes.
            </p>
            <h4>Helpful resources</h4>
            <p>
              While you wait for your application to be processed, check out
              these helpful resources for being successful on doggos.com:
            </p>
            <ul>
              <li>
                <Link to={'/getting-started-as-a-dog-sitter'}>
                  Getting started as a dog sitter on doggos.com
                </Link>
              </li>
              <li>
                <Link to={'/editing-your-dog-sitter-profile'}>
                  Editing your profile
                </Link>
              </li>
              <li>
                {' '}
                <Link to={'/how-do-refunds-work-for-dog-sitters'}>
                  How do refunds work?
                </Link>
              </li>
              <li>
                <Link to={'/how-do-payments-work-for-dog-sitters'}>
                  How do payments work?
                </Link>
              </li>
            </ul>
            <p>
              Thank you for taking the time to apply! We look forward to having
              you as a user and a member of our community.
            </p>
          </div>
        </div>
      )}
      {/* Form */}
      {!submitted && (
        <>
          <div className={style.hero}>
            <h1>Apply to be a dog sitter</h1>
            <p>
              Before you can create an account, you'll need to tell us a little
              bit about yourself.
            </p>
          </div>
          <Form {...form} noValidate={false} className={style.form}>
            <h2>About you</h2>
            <p>
              We review every application to protect our community. Please
              ensure all information is accurate, or we may not be able to
              approve your account.
            </p>
            <div className={style.formSegment}>
              <div className={style.formRow}>
                <div className={style.inputGroup}>
                  <FormLabel {...form} htmlFor='name'>
                    Business name <span className={style.required}>*</span>
                  </FormLabel>
                  <FormInput
                    className={style.input}
                    {...form}
                    type='text'
                    name='name'
                    placeholder="e.g 'Las Vegas K9 Training'"
                    pattern="[A-Za-z0-9' ]+"
                    minLength='4'
                    required
                  />
                </div>
                <div className={style.inputGroup}>
                  <FormLabel {...form} htmlFor='email'>
                    Your email address <span className={style.required}>*</span>
                  </FormLabel>
                  <FormInput
                    className={style.input}
                    {...form}
                    type='email'
                    name='email'
                    required
                  />
                </div>
              </div>
              <div className={style.formRow}>
                <div className={style.inputGroup}>
                  <FormLabel {...form} htmlFor='phone'>
                    Phone number
                  </FormLabel>
                  <FormInput
                    className={style.input}
                    {...form}
                    type='tel'
                    name='phone'
                    placeholder='(702) 555-5555'
                    // pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" // something is wrong w this
                  />
                </div>
                <div className={style.inputGroup}>
                  <FormLabel {...form} htmlFor='website'>
                    Website
                  </FormLabel>
                  <FormInput
                    className={style.input}
                    {...form}
                    type='url'
                    name='website'
                    placeholder='https://doggos.com'
                  />
                </div>
              </div>
              <div className={style.formRow}>
                <div className={style.inputGroup}>
                  <FormLabel {...form} name='categories'>
                    Categories <span className={style.required}>*</span>{' '}
                    <span className={style.note}>(Limit 3)</span>
                  </FormLabel>
                  <div className={style.checkboxContainer}>
                    <label className={style.checkboxGroup}>
                      <FormCheckbox
                        className={style.checkbox}
                        {...form}
                        name='categories'
                        value='waste-removal'
                        disabled={
                          !form.values.categories.includes('waste-removal') &&
                          form.values.categories.length >= 3
                        }
                      />{' '}
                      <div className={style.checkboxLabel}>Waste removal</div>
                    </label>
                    <label className={style.checkboxGroup}>
                      <FormCheckbox
                        className={style.checkbox}
                        {...form}
                        name='categories'
                        value='dog-training'
                        disabled={
                          !form.values.categories.includes('dog-training') &&
                          form.values.categories.length >= 3
                        }
                      />{' '}
                      <div className={style.checkboxLabel}>Dog training</div>
                    </label>
                    <label className={style.checkboxGroup}>
                      <FormCheckbox
                        className={style.checkbox}
                        {...form}
                        name='categories'
                        value='dog-grooming'
                        disabled={
                          !form.values.categories.includes('dog-grooming') &&
                          form.values.categories.length >= 3
                        }
                      />
                      <div className={style.checkboxLabel}>Dog grooming</div>
                    </label>
                    <label className={style.checkboxGroup}>
                      <FormCheckbox
                        className={style.checkbox}
                        {...form}
                        name='categories'
                        value='veterinarian'
                        disabled={
                          !form.values.categories.includes('veterinarian') &&
                          form.values.categories.length >= 3
                        }
                      />
                      <div className={style.checkboxLabel}>Veterinarian</div>
                    </label>
                    <label className={style.checkboxGroup}>
                      <FormCheckbox
                        className={style.checkbox}
                        {...form}
                        name='categories'
                        value='pet-store'
                        disabled={
                          !form.values.categories.includes('pet-store') &&
                          form.values.categories.length >= 3
                        }
                      />
                      <div className={style.checkboxLabel}>Pet store</div>
                    </label>
                    <label className={style.checkboxGroup}>
                      <FormCheckbox
                        className={style.checkbox}
                        {...form}
                        name='categories'
                        value='dog-rescue'
                        disabled={
                          !form.values.categories.includes('dog-rescue') &&
                          form.values.categories.length >= 3
                        }
                      />
                      <div className={style.checkboxLabel}>Dog rescue</div>
                    </label>
                    <label className={style.checkboxGroup}>
                      <FormCheckbox
                        className={style.checkbox}
                        {...form}
                        name='categories'
                        value='dog-breeder'
                        disabled={
                          !form.values.categories.includes('dog-breeder') &&
                          form.values.categories.length >= 3
                        }
                      />{' '}
                      <div className={style.checkboxLabel}>Breeding</div>
                    </label>
                    <label className={style.checkboxGroup}>
                      <FormCheckbox
                        {...form}
                        className={style.checkbox}
                        name='categories'
                        value='pet-transport'
                        disabled={
                          !form.values.categories.includes('pet-transport') &&
                          form.values.categories.length >= 3
                        }
                      />{' '}
                      <div className={style.checkboxLabel}>Transportation</div>
                    </label>
                    <label className={style.checkboxGroup}>
                      <FormCheckbox
                        className={style.checkbox}
                        {...form}
                        name='categories'
                        value='dog-food'
                        disabled={
                          !form.values.categories.includes('dog-food') &&
                          form.values.categories.length >= 3
                        }
                      />{' '}
                      <div className={style.checkboxLabel}>Food & treats</div>
                    </label>
                    <label className={style.checkboxGroup}>
                      <FormCheckbox
                        {...form}
                        className={style.checkbox}
                        name='categories'
                        value='dog-boarding'
                        disabled={
                          !form.values.categories.includes('dog-boarding') &&
                          form.values.categories.length >= 3
                        }
                      />{' '}
                      <div className={style.checkboxLabel}>Boarding</div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className={style.formSegment}>
              {/* <h2>Business details</h2> */}
              <div className={style.formRow}>
                {typeof window !== 'undefined' && (
                  <React.Suspense fallback={null}>
                    <div className={style.inputGroup}>
                      <FormLabel {...form} htmlFor='streetAddress'>
                        Street address
                      </FormLabel>
                      <div className={style.geoapifyInput}>
                        <input
                          className={style.input}
                          {...form}
                          name='streetAddress'
                          placeholder='Street address'
                          skipDetails
                        />
                      </div>
                    </div>
                    <div className={style.inputGroup}>
                      <FormLabel {...form} htmlFor='city'>
                        City <span className={style.required}>*</span>
                      </FormLabel>
                      <div className={style.geoapifyInput}>
                        <input {...form} name='city' placeholder='City' />
                      </div>
                    </div>
                    <div className={style.inputGroup}>
                      <FormLabel {...form} htmlFor='state'>
                        State <span className={style.required}>*</span>
                      </FormLabel>
                      <div className={style.geoapifyInput}>
                        <input name='state' placeholder='State' {...form} />
                      </div>
                    </div>
                  </React.Suspense>
                )}
              </div>
              <div className={style.formRow}>
                <div className={style.inputGroup}>
                  <FormLabel {...form} htmlFor='instagram'>
                    Instagram URL <span className={style.note}>(Optional)</span>
                  </FormLabel>
                  <FormInput
                    className={style.input}
                    {...form}
                    type='text'
                    name='instagram'
                    placeholder='https://instagram.com/doggosdotcom'
                  />
                </div>
                <div className={style.inputGroup}>
                  <FormLabel {...form} htmlFor='facebook'>
                    Facebook page URL{' '}
                    <span className={style.note}>(Optional)</span>
                  </FormLabel>
                  <FormInput
                    className={style.input}
                    {...form}
                    type='text'
                    name='facebook'
                    placeholder='https://facebook.com/doggosdotcom'
                  />
                </div>
              </div>
              <div className={style.formRow}>
                <div className={style.inputGroup}>
                  <FormLabel {...form} htmlFor='businessDescription'>
                    Description of business{' '}
                    <span className={style.note}>
                      (At least 100 characters recommended)
                    </span>
                  </FormLabel>
                  <FormInput
                    className={style.textarea}
                    {...form}
                    as='textarea'
                    name='businessDescription'
                  />
                </div>
              </div>
            </div>
            <div className={style.formRow}>
              <div className={style.inputGroup}>
                <FormLabel {...form} htmlFor='image'>
                  Cover photo{' '}
                  <span className={style.note}>(Dimensions: 400 x 750px)</span>
                </FormLabel>
                <FormInput
                  {...form}
                  ref={imageInputRef}
                  className={style.input}
                  type='file'
                  name='image'
                  accept='image/*'
                />
                <FormMessage className={style.error} {...form} name='image' />
              </div>
              <div className={style.inputGroup}>
                <FormLabel {...form} htmlFor='logo'>
                  Logo{' '}
                  <span className={style.note}>(Dimensions: 200 x 200px)</span>
                </FormLabel>
                <FormInput
                  {...form}
                  ref={iconInputRef}
                  className={style.input}
                  type='file'
                  name='logo'
                  accept='image/png, image/jpeg'
                />
                <FormMessage className={style.error} {...form} name='logo' />
              </div>
            </div>
            <FormSubmitButton {...form} type='submit'>
              Submit for review
            </FormSubmitButton>
          </Form>
        </>
      )}
    </Template>
  )

  function getStaticProps() {
    return {
      props: {
        mapboxKey: props.env.MAPBOX_TOKEN,
      },
    }
  }
}

export default PageAddBusiness
